import React from 'react';
import './BucketList.css';

const BucketListStatus = {
  TODO: 'TODO',
  PLANNED: 'PLANNED',
  INPROGRESS: 'INPROGRESS',
  COMPLETED: 'COMPLETED',
  POSTPONED: 'POSTPONED',
};

const BucketListItems = [
  { id: 1, title: 'Live Rent-Free', description: 'Owning my own existence! F$#K paying it to my landlord!', status: BucketListStatus.COMPLETED, link: 'https://www.tiktok.com/@corporate100/video/7359326032895184171' },
  { id: 2, title: 'Buzz My Hair', description: 'Always wanted to be bald :)', status: BucketListStatus.PLANNED },
  { id: 3, title: 'Explore an abandoned building at night, alone 😱', description: 'Want to experience some sp00ky paranormal activity!', status: BucketListStatus.TODO },
  { id: 4, title: 'Surf in the barrel of a wave', description: 'Catch the perfect wave and ride inside the barrel!', status: BucketListStatus.TODO },
  { id: 5, title: 'Rock climb a cool cliff', description: 'Conquer a badass cliff face!', status: BucketListStatus.POSTPONED, link: 'https://www.tiktok.com/@corporate100/video/7376068292722707754' },
  { id: 6, title: 'Backcountry Snowboard down Mount Rainier', description: 'Shred the gnarly backcountry slopes of Mount Rainier!', status: BucketListStatus.TODO },
  { id: 7, title: 'See a grizzly bear stand up on its hind legs', description: 'Witness the power of a grizzly bear up close!', status: BucketListStatus.COMPLETED, link: 'https://www.tiktok.com/@corporate100/video/7413097709432884511' },
  { id: 8, title: 'Go Dog Sledding 🦮🛷', description: 'Mush! Experience the thrill of dog sledding!', status: BucketListStatus.COMPLETED, link: 'https://www.tiktok.com/@corporate100/video/7410553468454309150' },
  { id: 9, title: 'Ride a horse through a canyon', description: 'Giddy up! Ride a horse through a breathtaking canyon!', status: BucketListStatus.TODO },
  { id: 10, title: 'Sandboard down a Dune', description: 'Feel the rush of sandboarding down a massive dune!', status: BucketListStatus.PLANNED },
  { id: 11, title: 'Spearfish 𐃆🐠 in the Ocean', description: 'Catch a fish and cook it up for dinner!', status: BucketListStatus.TODO },
  { id: 12, title: 'Sky Dive Over the Grand Canyon', description: 'Feel the ultimate adrenaline rush while skydiving!', status: BucketListStatus.TODO },
  { id: 13, title: 'Run a sub-7 minute mile', description: 'Push my limits and run a mile in under 7 minutes!', status: BucketListStatus.COMPLETED },
  { id: 14, title: 'Relax in a Hot Spring ', description: 'Soak and unwind in a natural hot spring!', status: BucketListStatus.COMPLETED, link: 'https://www.tiktok.com/@corporate100/video/7384992521476230443' },
  { id: 15, title: 'Touch my toes', description: 'Achieve the flexibility to touch my toes!', status: BucketListStatus.INPROGRESS },
  { id: 16, title: 'See a whale breaching 🐋', description: 'Witness the majestic sight of a whale breaching!', status: BucketListStatus.POSTPONED, link: 'https://www.tiktok.com/@corporate100/video/7401929561304239390' },
  { id: 17, title: 'Drink Glacier Water 🧊🏔️', description: 'Sip the purest water on this heavenly earth!!', status: BucketListStatus.COMPLETED, link: 'https://www.tiktok.com/@corporate100/video/7406107171638889759' },
  { id: 18, title: 'Run a mushroom soup stand on a mountain', description: 'Serve up some delicious mushroom soup on a mountain!', status: BucketListStatus.TODO },
  { id: 19, title: 'Run a message in a bottle service', description: 'Pass on anonymous messages to strangers!', status: BucketListStatus.INPROGRESS },
  { id: 20, title: 'Be a beekeeper', description: 'Experience the buzz and become a beekeeper for a day!', status: BucketListStatus.TODO },
  { id: 21, title: 'Wake up a beautiful sunrise', description: 'No more waking up to concrete parking lots!', status: BucketListStatus.TODO },
  { id: 22, title: 'Paint a mural', description: 'Express myself by painting a large-scale mural!', status: BucketListStatus.TODO },
  { id: 23, title: 'Film 100 species of animals', description: 'Capture the beauty of 100 different animal species with my camera!', status: BucketListStatus.INPROGRESS, link: 'https://www.tiktok.com/@corporate100/video/7386161076032785710' },
  { id: 24, title: 'Have Joji gain some courage', description: 'Help my cat Joji become braver and more adventurous!', status: BucketListStatus.INPROGRESS },
  { id: 25, title: 'Dye my hair', description: 'Switch up my look by dyeing my hair!', status: BucketListStatus.PLANNED },
  { id: 26, title: 'Swim with sharks', description: 'Get over my fear of sharks! 😓', status: BucketListStatus.TODO },
  { id: 27, title: 'Stay a Night at a Firewatch Tower', description: 'Live the life of Firewatch', status: BucketListStatus.PLANNED },
  { id: 28, title: 'Enter a Food Eating Competition', description: 'Gorge Myself to the extreme!', status: BucketListStatus.TODO },
  { id: 29, title: 'Rest with the Ferns on a Forest Floor', description: 'Pretend that I am a plant', status: BucketListStatus.PLANNED },
  { id: 30, title: 'See the Northern Lights', description: 'Aurora Borealis', status: BucketListStatus.COMPLETED },
  { id: 31, title: 'Get my First Tattoo', description: 'Ink up! Get a meaningful tattoo!', status: BucketListStatus.POSTPONED },
  { id: 32, title: 'Backpack in Banff', description: 'Do a backpacking trip in Banff National Park!', status: BucketListStatus.COMPLETED, link: 'https://www.tiktok.com/@corporate100/video/7394997143213215019' },
  { id: 33, title: 'Frolick in a Wildflower Field', description: 'Sound of Music', status: BucketListStatus.COMPLETED },
  { id: 34, title: 'Make a piece of pottery', description: 'Get my hands dirty and create a unique pottery piece!', status: BucketListStatus.TODO },
  { id: 35, title: 'Outer Wilds 🪐🔭', description: 'Stargaze with a telescope!', status: BucketListStatus.PLANNED },
  { id: 36, title: 'Read and review 10 books', description: 'Expand my mind by reading and reviewing 10 books!', status: BucketListStatus.INPROGRESS },
  // { id: 8, title: 'Hang out with some penguins', description: 'Chill with some adorable penguins in their natural habitat!', status: BucketListStatus.TODO },
  // { id: 20, title: 'Play that one song from Last of Us on Guitar', description: 'Strum the iconic song from The Last of Us on my guitar!', status: BucketListStatus.TODO },
  // { id: 22, title: 'Swim in a cenote', description: 'Take a refreshing dip in a magical cenote!', status: BucketListStatus.TODO },
  // { id: 27, title: 'Camp somewhere cool', description: 'Set up camp in an epic location!', status: BucketListStatus.TODO },
  // { id: 28, title: 'Ride a motorcycle', description: 'Feel the freedom of riding a motorcycle!', status: BucketListStatus.TODO },
  // { id: 31, title: 'Be a hero', description: 'Do something heroic and make a difference!', status: BucketListStatus.TODO },
  // { id: 33, title: 'Swim with bioluminescent algae', description: 'Dive into waters glowing with bioluminescent algae!', status: BucketListStatus.TODO },
  // { id: 39, title: 'Forge my own sword', description: 'Channel my inner blacksmith and forge a sword!', status: BucketListStatus.TODO },
  // { id: 40, title: 'Go to the renaissance faire', description: 'Travel back in time and enjoy the renaissance faire!', status: BucketListStatus.TODO },
];

const getBadgeClass = (status) => {
  switch (status) {
    case BucketListStatus.TODO:
      return 'badge-todo';
    case BucketListStatus.PLANNED:
      return 'badge-planned';
    case BucketListStatus.INPROGRESS:
      return 'badge-in-progress';
    case BucketListStatus.COMPLETED:
      return 'badge-completed';
    case BucketListStatus.POSTPONED:
      return 'badge-postponed';
    default:
      return '';
  }
};

const BucketList = () => (
  <div>
    {BucketListItems.map((exp, index) => (
      <div key={exp.id} className={`experience-item ${exp.status === BucketListStatus.COMPLETED ? 'completed' : exp.status === BucketListStatus.INPROGRESS ? 'inprogress' : exp.status === BucketListStatus.PLANNED ? 'planned' : 'incomplete'}`}>
        <span className={`badge ${getBadgeClass(exp.status)}`}>{exp.status}</span>
        <h3>
          {exp.status === BucketListStatus.COMPLETED && '✅'} {index + 1}. {exp.title}
        </h3>
        <p>{exp.description}</p>
        {
          exp.status === BucketListStatus.COMPLETED && exp.link ? (
            <a className='link' href={exp.link} target="_blank" rel="noopener noreferrer">
              <b>Watch on TikTok</b>
            </a>
          ) :
            exp.status === BucketListStatus.COMPLETED && !exp.link ? (
              <h4>
                Video Coming Soon!
              </h4>
            ) :
              exp.status !== BucketListStatus.COMPLETED && exp.link ? (
                <a href={exp.link} target="_blank" rel="noopener noreferrer">
                  Watch What I Have So Far on Tiktok!
                </a>
              ) :
                <a href={`mailto:danny@corporate-100.com?subject=I want to help you with #${index + 1}. ${exp.title}!!`} target="_blank" rel="noopener noreferrer">
                  Want to Help Me Out? Contact Me about this Specific Item!
                </a>}
      </div>
    ))}
  </div>
);

export default BucketList;