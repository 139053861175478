import React from 'react';
import BucketList from './BucketList';
import './App.css';

const LINKS = {
  contact: 'mailto:danny@corporate-100.com',
  tiktok: 'https://www.tiktok.com/@corporate100',
  insta: 'https://www.instagram.com/corporate.100/',
  youtube: 'https://www.youtube.com/@corporate100',
}

function ContactBubble() {
  return (
    <div className='contact-info'>
      <div class="contact-row">
        <h3>Contact Me! 📥</h3>
        <a href={LINKS.contact} target="_blank" rel="noopener noreferrer">danny@corporate-100.com</a>
      </div>
      <div class="contact-row">
        <h3>TikTok! 💌</h3>
        <a href={LINKS.tiktok} target="_blank" rel="noopener noreferrer">@corporate100</a>
      </div>
      <div class="contact-row">
        <h3>Instagram! 📷</h3>
        <a href={LINKS.insta} target="_blank" rel="noopener noreferrer">@corporate.100</a>
      </div>
      <div class="contact-row">
        <h3>Youtube! 📺</h3>
        <a href={LINKS.youtube} target="_blank" rel="noopener noreferrer">@corporate100</a>
      </div>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>💯🪣 Corporate 100: Bucket List! 🐈‍⬛🤷🏻‍♂️</h1>
        <p>100 Things I Want to do Before I Become a Corporate Slave!</p>
      </header>
      <main>
        <ContactBubble />
        <BucketList />
      </main>
      <footer>
        <p>© 2024 Corporate100 LLC</p>
      </footer>
    </div >
  );
}

export default App;
